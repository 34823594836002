import React, { useEffect, useState } from "react";
import axios from "../../axios";
import "./style.scss";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
} from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Voucher from "../../components/voucher/Vouchers";
const useStyles = makeStyles((theme) => ({
    paperTableHeight: {
        width: "95%",
        marginLeft: "2rem",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        border: "1px solid #D9E3D9",
        overflowX: "scroll",
    },
    tableMainContainer: {
        overflowX: "scroll",
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
    },
    tableHeadingRow: {
        // backgroundColor: "#21AFE6",
    },
    tablseHeadingCell: {
        padding: "0.8rem 20px !important",
        textAlign: "center",
        fontSize: "14px",
        color: "#264503",
        fontWeight: 600,
        borderBottom: "1px solid #D9E3D9",
        whiteSpace: "nowrap",
    },
    textMiddle: {
        padding: "0.6rem 20px !important",
        verticalAlign: "middle !important",
        textAlign: "center",
        fontSize: "14px",
        color: "#001050",
    },
    tablePaginationStyle: {
        borderTop: "1px solid #0000001a",
        borderRadius: "0rem 0rem 0.4rem 0.4rem",
        overflowY: "hidden",
    },
    button: {
        textAlign:"center",
        backgroundColor:"#88AE40",
        padding:"7px 30px",
        color:"white",
        width:"250px",
        border:"none",
        borderRadius:"4px"
    },
}));
function OtherReward() {
    const history = useHistory()
    const classes = useStyles();
    const [senderValue, setSenderValue] = useState();
    const [stateValue,setStateValue]=useState(1);
   

    return (
        <>
            <div className="hewe-cont">
                <div className="referralcont">
                    <div className="flex-align-center">
                        <div className="dot me-2"></div>
                        <h2 className="font-dark-green">Manage Reward Category</h2>
                    </div>
                    <div className="rewardcardcont">
                        <div className="row">
                            <div className="col-2">
                                <div className="cardcont" onClick={()=>setStateValue(1)} style={stateValue==1? {backgroundColor:"#599265E0",color:"white"}:{backgroundColor:"#EFEFEF"}}>
                                    <h4>Vouchers</h4>
                                </div>
                            </div>
                            <div className="col-2">
                            <div className="cardcont" onClick={()=>setStateValue(2)} style={stateValue==2? {backgroundColor:"#599265E0",color:"white"}:{backgroundColor:"#EFEFEF"}}>
                                    <h4>In App</h4>
                                </div>
                            </div>
                            <div className="col-2">
                            <div className="cardcont" onClick={()=>setStateValue(3)} style={stateValue==3? {backgroundColor:"#599265E0",color:"white"}:{backgroundColor:"#EFEFEF"}}>
                                    <h4>Audit Token</h4>
                                </div>
                            </div>
                            <div className="col-2">
                            <div className="cardcont" onClick={()=>setStateValue(4)} style={stateValue==4? {backgroundColor:"#599265E0",color:"white"}:{backgroundColor:"#EFEFEF"}}>
                                    <h4>Premium</h4>
                                </div>
                            </div>
                            <div className="col-2">
                            <div className="cardcont" onClick={()=>setStateValue(5)} style={stateValue==5? {backgroundColor:"#599265E0",color:"white"}:{backgroundColor:"#EFEFEF"}}>
                                    <h4>trip</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                  {
                    stateValue==1?<>
                    <Voucher/>
                    </>:null
                  }
                </div>
            </div>
        </>
    );
}

export default OtherReward;
