import React, { useState,useEffect } from 'react'
import { MdArrowBackIosNew } from 'react-icons/md'
import { FaPlus } from "react-icons/fa";
import { Field, Form, Formik } from 'formik';
import Input from '../Input';
import { UploadImage } from '../uplaodImage';
import { TiDelete } from "react-icons/ti";
import { createVoucherValidator } from '../../utils/validators';
import axios from "../../axios"
import { toast } from 'react-toastify';
import Overlay from '../Overlay';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { RiEditFill } from 'react-icons/ri';
export default function EditVoucher() {
  let { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const history=useHistory()  
  const [voucherValue, setVoucherValue] = useState({
    brandIcon: "",
    brandName: "",
    voucherCode: "",
    quantity: "",
    probability: "",
    rewardExpireDate: "",
    rewardExpireTime: "",
    voucherExpireDate: "",
    voucherExpireTime: "",
    description: ""
  })
  const [brandImg, setBrandImg] = useState("")
  
  const uploadfile = async (e, formikBag) => {
    const file = e.target.files[0];
    
    if (file) {
      const img = new Image();
      const objectUrl = URL.createObjectURL(file);
      
      img.onload = async function () {
        if (this.width > 128 || this.height > 128) {
          console.error("Image dimensions should not exceed 128x128.");
          toast.error("Image dimensions should not exceed 128x128.")
          URL.revokeObjectURL(objectUrl);
          return;
        }
  
        const url = await UploadImage(file);
        console.log(url);
        if (url && url.data && url.data.image_url) {
          formikBag.setFieldValue("brandIcon", url.data.image_url);
          setBrandImg(url.data.image_url);
        } else {
          console.error("Failed to upload or get image URL.");
        }
  
        URL.revokeObjectURL(objectUrl);
      };
  
      img.src = objectUrl;
    } else {
      console.error("No file selected.");
    }
  };
  const getData = async () => {
    setIsLoading(true);
    try {
      const data = await axios.get(`private/getReward/${id}`);
      setVoucherValue(data?.data?.data?.vouchers);
      setBrandImg(data?.data?.data?.vouchers?.brandIcon)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }
  useEffect(() => {
    getData()
  }, [])
  const handleCreateVoucher = async (value) => {
    setIsLoading(true)
    try {
      let formData = {
        categoryName: "1",
        vouchers: {
          brandIcon: value.brandIcon,
          brandName: value.brandName,
          voucherCode: value.voucherCode,
          quantity: Number(value.quantity),
          probability: Number(value.probability),
          rewardExpireTime: value.rewardExpireTime,
          rewardExpireDate: value.rewardExpireDate,
          voucherExpireTime: value.voucherExpireTime,
          voucherExpireDate: value.voucherExpireDate,
          description: value.description
        }
      };
      const data = await axios.put(`/private/editReward/${id}`, formData);
      toast.success(data?.data?.message);
      history.push("/other-reward")
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      toast.error(error.response.data?.errors[0]?.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  return (
    <div className="hewe-cont">
      <div className="referralcont">
        <div className="flex-align-center justify-content-between">
          <div className='flex-align-center'>
            <div className="me-2"><MdArrowBackIosNew style={{ fontSize: "30px" }} onClick={()=>history.push("/other-reward")} /></div>
            <h2 className="font-dark-green mb-0">Edit Voucher</h2>
          </div>
        </div>

        <Formik
          enableReinitialize
          initialValues={voucherValue}
          validate={(values)=> createVoucherValidator(values)}
          validateOnChange
          onSubmit={(values) => handleCreateVoucher(values)}
        >
          {(formikBag) => {
            { console.log(formikBag) }
            return (
              <Form className='vocherform'>
                <div className="row">
                  <div className="col-2">
                    <div>
                      <label className='text-center'>Voucher icon</label>
                    <div className="imgcont">
                      <input
                        type="file"
                        id="avatar-upload"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={(e) => uploadfile(e, formikBag)}
                      />
                      {
                        brandImg != "" ?
                          <img src={brandImg} alt="img" className='brandimg' />
                          : <FaPlus className='plusIcon' onClick={() => document.getElementById("avatar-upload").click()} />
                      }
                      {
                        brandImg != "" ?
                          <RiEditFill className='edit' onClick={() => document.getElementById("avatar-upload").click()} />
                          : null
                      }
                    </div>
                    <p className='error'>{
                                formikBag.touched.brandIcon &&
                                  formikBag.errors.brandIcon
                                  ? formikBag.errors.brandIcon
                                  : null
                              }</p>
                    </div>
                    <div className="mt-3">
                        <label htmlFor="">Brand Name</label>
                        <Field name="brandName" className="inptCont">
                          {({ field}) => (
                            <Input
                              {...field}
                              type="text"
                              name="brandName"
                              value={formikBag.values.brandName}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "brandName",
                                  e.target.value
                                );
                              }}
                              error={
                                formikBag.touched.brandName &&
                                  formikBag.errors.brandName
                                  ? formikBag.errors.brandName
                                  : null
                              }
                              className="form-control"
                              placeholder="Enter Brand Name"
                            /> 
                          )}
                        </Field>

                      </div>
                  </div>
                  <div className='col-10'>
                    <div className="row">
                    <div className="col-3 mb-4">
                        <label htmlFor="">Reward Expiry Time</label>
                        <Field className="inptCont">
                          {({ field }) => (
                            <Input
                              {...field}
                              type="time"
                              value={formikBag.values.rewardExpireTime}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "rewardExpireTime",
                                  e.target.value
                                );
                              }}
                              error={
                                formikBag.touched.rewardExpireTime &&
                                  formikBag.errors.rewardExpireTime
                                  ? formikBag.errors.rewardExpireTime
                                  : null
                              }
                              className="form-control"
                            />
                          )}
                        </Field>
                      </div>
                       <div className="col-3 mb-4">
                        <label htmlFor="" placeholder='Select Date' >Reward Expiry Date</label>
                        <Field className="inptCont">
                          {({ field }) => (
                            <Input
                              {...field}
                              type="date"
                              value={formikBag.values.rewardExpireDate}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "rewardExpireDate",
                                  e.target.value
                                );
                              }}
                              error={
                                formikBag.touched.rewardExpireDate &&
                                  formikBag.errors.rewardExpireDate
                                  ? formikBag.errors.rewardExpireDate
                                  : null
                              }
                              className="form-control"
                            />
                          )}
                        </Field>
                      </div>
                      <div className="col-4 mb-4">
                        <label htmlFor="">Voucher Code</label>
                        <Field className="inptCont">
                          {({ field }) => (
                            <Input
                              {...field}
                              type="text"
                              value={formikBag.values.voucherCode}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "voucherCode",
                                  e.target.value
                                );
                              }}
                              error={
                                formikBag.touched.voucherCode &&
                                  formikBag.errors.voucherCode
                                  ? formikBag.errors.voucherCode
                                  : null
                              }
                              className="form-control"
                              placeholder="Enter Voucher Code"
                            />
                            
                          )}
                        </Field>
                       
                      </div>  
                      <div className="col-3 mb-4">
                        <label htmlFor="" placeholder='Enter Quantity'>Quantity</label>
                        <Field className="inptCont">
                          {({ field }) => (
                            <Input
                              {...field}
                              type="number"
                              value={formikBag.values.quantity}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "quantity",
                                  e.target.value
                                );
                              }}
                              error={
                                formikBag.touched.quantity &&
                                  formikBag.errors.quantity
                                  ? formikBag.errors.quantity
                                  : null
                              }
                              className="form-control"
                            />
                          )}
                        </Field>

                      </div>
                      <div className="col-3 mb-4">
                        <label htmlFor="" placeholder='Enter Probability'>Probability</label>
                        <Field className="inptCont">
                          {({ field }) => (
                            <Input
                              {...field}
                              type="number"
                              value={formikBag.values.probability}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "probability",
                                  e.target.value
                                );
                              }}
                              error={
                                formikBag.touched.probability &&
                                  formikBag.errors.probability
                                  ? formikBag.errors.probability
                                  : null
                              }
                              className="form-control"
                            />
                          )}
                        </Field>

                      </div>
                      <div className="col-3 mb-4">
                        <label htmlFor="">Voucher Expiry Time</label>
                        <Field className="inptCont">
                          {({ field }) => (
                            <Input
                              {...field}
                              type="time"
                              value={formikBag.values.voucherExpireTime}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "voucherExpireTime",
                                  e.target.value
                                );
                              }}
                              error={
                                formikBag.touched.voucherExpireTime &&
                                  formikBag.errors.voucherExpireTime
                                  ? formikBag.errors.voucherExpireTime
                                  : null
                              }
                              className="form-control"
                            />
                          )}
                        </Field>
                      </div>
                       <div className="col-3 mb-4">
                        <label htmlFor="" placeholder='Select Date' >Voucher Expiry Date</label>
                        <Field className="inptCont">
                          {({ field }) => (
                            <Input
                              {...field}
                              type="date"
                              value={formikBag.values.voucherExpireDate}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "voucherExpireDate",
                                  e.target.value
                                );
                              }}
                              error={
                                formikBag.touched.voucherExpireDate &&
                                  formikBag.errors.voucherExpireDate
                                  ? formikBag.errors.voucherExpireDate
                                  : null
                              }
                              className="form-control"
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12">
                    <label htmlFor="">How to Use</label>
                    <div className="inptCont">
                      <textarea name="" id="" value={formikBag.values.description} placeholder='type here....' onChange={(e) => formikBag.setFieldValue("description", e.target.value)}></textarea>
                      <p className='error'>{
                                formikBag.touched.description &&
                                  formikBag.errors.description
                                  ? formikBag.errors.description
                                  : null
                              }</p>
                    </div>
                  </div>
                </div>
                <div>
                  <button type="submit" className='savebtn' >Save</button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
      {isLoading && <Overlay />}
    </div>
    
  )
}
